<template>
    <collapsable-filter-bar
        :active="activeFilters.isDirty"
        :is-processing="isProcessing"
        @clear="clearFilters"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                v-model="activeFilters.search"
                :is-processing="isProcessing"
                :placeholder="__('assets.overview.filter.placeholder')"
                :show-spinner="!isOpen"
            />
        </template>

        <location-filter v-model="activeFilters.locations" />
        <div>
            <multi-select-dropdown
                v-if="types.length > 1"
                v-model="activeFilters.types"
                :label="__('assets.overview.filter.type')"
                :options="types"
                :show-select-all-button="false"
            >
                <template v-slot:label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center"
                        style="gap: 1rem"
                    >
                        <span class="mr-3">{{ option.label }}</span
                        ><small class="text-muted">{{ option.value }}</small>
                    </div>
                </template>
            </multi-select-dropdown>
        </div>
        <div v-if="usages.length > 1">
            <multi-select-dropdown
                v-model="activeFilters.usages"
                :label="__('assets.overview.filter.usage')"
                :options="usages"
                :show-select-all-button="false"
            />
        </div>
        <div v-if="operabilities.length > 1">
            <multi-select-dropdown
                v-model="activeFilters.operabilities"
                :label="__('assets.overview.filter.operability')"
                :options="operabilities"
                :show-select-all-button="false"
                class="operability-filter"
            >
                <template v-slot:label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center"
                        style="gap: 0.5rem"
                    >
                        <span>{{ option.label }}</span
                        ><em
                            :class="option.value"
                            class="operability-indicator"
                        ></em>
                    </div>
                </template>
            </multi-select-dropdown>
        </div>
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import JetInput from '@/Jetstream/Input.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';

export default {
    components: {
        LocationFilter,
        SearchFilter,
        CollapsableFilterBar,
        JetInput,
        MultiSelectDropdown,
    },
    props: {
        isProcessing: Boolean,
    },
    data() {
        return {
            activeFilters: this.$inertia.form({
                search: '',
                locations: [],
                types: [],
                usages: [],
                operabilities: [],
            }),
        };
    },
    computed: {
        types() {
            return this.$page.props.types?.data || [];
        },
        operabilities() {
            return this.$page.props.operabilities?.data || [];
        },
        usages() {
            return this.$page.props.usages?.data || [];
        },
    },
    created() {
        this.readValuesFromUrl();
        window.addEventListener('keyup', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keyup', this.keyboardListener);
    },

    mounted() {
        this.readValuesFromUrl();

        this.$watch(
            (vm) => [
                vm.activeFilters.search,
                vm.activeFilters.locations,
                vm.activeFilters.types,
                vm.activeFilters.usages,
                vm.activeFilters.operabilities,
            ],
            () => {
                this.$emit('change', this.activeFilters.data());
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },
    methods: {
        resetFilters() {
            this.activeFilters.reset();
        },
        readValuesFromUrl() {
            const { search, locations, operabilities, usages, types } =
                this.route().params;

            this.searchTerm = search || '';
            this.activeFilters.search = search || '';
            this.activeFilters.locations = locations || [];
            this.activeFilters.operabilities = operabilities || [];
            this.activeFilters.usages = usages || [];
            this.activeFilters.types = types || [];
        },
        clearFilters() {
            this.resetFilters();
        },
    },
};
</script>
<style scoped>
.operability-indicator {
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    border-radius: 100%;
    border: 1px solid;
}

.operability-indicator.operable {
    border-color: #8e8e93;
}

.operability-indicator.inoperable {
    border-color: #8e8e93;
}

:deep(.operability-filter .c-multi-select-option) {
    padding-right: 0.625rem !important;
}
</style>
