<template>
    <app-layout>
        <page-header :title="__('test_records.overview.header')">
            <div class="d-flex justify-content-between align-items-start">
                <test-records-filterbar
                    :is-processing="isProcessing"
                    class="mt-2 w-100"
                    @change="updateFilter"
                />
                <jet-button
                    :disabled="true"
                    class="d-none d-sm-flex mt-2"
                    color="secondary"
                    >{{ __('general.action.export') }}
                </jet-button>
            </div>
        </page-header>

        <div class="d-flex justify-content-end">
            <small class="text-muted">
                {{
                    __('test_records.overview.results.count', {
                        count: `${tests.data.length}/${tests.meta.total}`,
                    })
                }}
            </small>
        </div>

        <data-table
            :columns="fields"
            :default-sorting="{ column: 'date', asc: false }"
            :items="tests.data"
            :no-items-view="noItemsView"
            :sticky-header="{ top: '152px' }"
            @update:sorter-value="updateSorting"
            @row-clicked="toggleDetails"
        >
            <template #date="{ item }">
                <td class="align-top">
                    <div class="text-muted">
                        <div class="text-nowrap">
                            {{ $filters.fullDate(item.date, currentLocale) }}
                        </div>
                        <small>
                            <icon icon="cil-clock" />
                            {{ $filters.time(item.date, currentLocale) }}</small
                        >
                    </div>
                </td>
            </template>

            <template #type="{ item }">
                <td>
                    <div v-if="item.asset">{{ item.asset?.type?.value }}</div>
                </td>
            </template>

            <template #designation="{ item }">
                <td>
                    <div v-if="item.asset">
                        <div>
                            {{ item.asset?.designation.label }}
                        </div>
                        <small class="text-nowrap text-dark"
                            >{{ __('assets.overview.cell.serial_number') }}:
                            {{ item.asset?.serialNumber }}</small
                        >
                    </div>
                </td>
            </template>

            <template #location="{ item }">
                <td>
                    <div v-if="item.asset">
                        {{ item.asset?.location.physical_location.iata_code }}
                    </div>
                </td>
            </template>

            <template #specific_location="{ item }">
                <td>
                    <div v-if="item.asset">
                        {{ item.asset?.location.name }}
                    </div>
                </td>
            </template>

            <template #user="{ item }">
                <td class="align-top">
                    <user-information v-if="item.user" :info="item.user" />
                    <span v-else>-</span>
                </td>
            </template>

            <template #result="{ item }">
                <td class="align-middle text-center">
                    <icon
                        v-if="item.resolution.value === 'success'"
                        v-c-tooltip="{
                            content: __(
                                'test_records.overview.tooltip.result.successful',
                            ),
                        }"
                        class="text-gray"
                        icon="cil-check-alt"
                        size="lg"
                    />
                    <icon
                        v-else
                        v-c-tooltip="{
                            content: __(
                                'test_records.overview.tooltip.result.failed',
                            ),
                        }"
                        class="text-danger"
                        icon="cil-warning"
                        size="lg"
                    />
                </td>
            </template>

            <template #report="{ item }">
                <td class="align-top">
                    <div
                        :class="{
                            'text-max-2-lines': !showFullDescription(item),
                        }"
                        class="text-break"
                        style="-webkit-line-clamp: 3"
                    >
                        {{ item.description }}
                    </div>
                </td>
            </template>

            <template #attachments="{ item }">
                <td class="align-top">
                    <FileList
                        :attachments="item.attachments"
                        :can-read="hasAnyPermission(['test.attachment.index'])"
                        :inline="true"
                        :readonly="true"
                    />
                </td>
            </template>

            <template #qr_code_scanned="{ item }">
                <td class="align-middle text-center">
                    <icon
                        v-if="item.qr_code_scanned_at"
                        v-c-tooltip="{
                            content: __(
                                'test_records.overview.tooltip.qr_code_scanned',
                            ),
                        }"
                        icon="cil-check-alt"
                        size="lg"
                    />
                    <icon
                        v-else
                        v-c-tooltip="{
                            content: __(
                                'test_records.overview.tooltip.qr_code_not_scanned',
                            ),
                        }"
                        icon="cil-x"
                        size="lg"
                    />
                </td>
            </template>
        </data-table>

        <pagination
            :only="['tests']"
            :paginator="tests"
            :query="activeQueryParams"
            class="mt-4"
        />
    </app-layout>
</template>
<script setup>
import PageHeader from '@/Components/PageHeader.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import UserInformation from '@/Components/UserInformation.vue';
import DataTable from '@/Components/DataTable.vue';
import { computed, ref } from 'vue';
import { trans } from '@/mixins/i18n.js';
import { router, usePage } from '@inertiajs/vue3';
import Pagination from '@/Components/Pagination.vue';
import JetButton from '@/Jetstream/Button.vue';
import FileList from '@/Components/FileList.vue';
import { when } from '@/Utils/when';
import TestRecordsFilterbar from '@/Pages/TestRecords/TestRecordsFilterbar.vue';
import { removeNullValues } from '@/mixins/removeNullValues.js';

const $page = usePage();
const isProcessing = ref(false);
const activeQueryParams = ref({ sort: { column: 'date', asc: false } });

const fields = computed(() =>
    [
        {
            key: 'date',
            label: trans('test_records.overview.cell.date'),
            _style: 'width:120px; min-width: 120px; max-width: 120px',
        },
        {
            key: 'type',
            label: trans('activities.overview.cell.type'),
            _style: 'width: 120px; min-width: 120px; max-width: 120px',
        },
        {
            key: 'designation',
            label: trans('activities.overview.cell.designation'),
            _style: 'width: 150px; min-width: 150px; max-width: 150px',
        },
        // Show column if there are multiple airports
        when(locations.value.data.length > 1, {
            key: 'location',
            label: trans('activities.overview.cell.location'),
            _style: 'width: 130px; min-width: 130px; max-width: 130px',
        }),
        {
            key: 'specific_location',
            label: trans('activities.overview.cell.specific_location'),
            _style: 'width: 15%',
        },
        {
            key: 'result',
            label: trans('test_records.overview.cell.result'),
            _style: 'width: 95px; min-width: 95px; max-width: 95px',
        },
        {
            key: 'report',
            label: trans('test_records.overview.cell.report'),
            _style: 'min-width: 350px',
        },
        {
            key: 'attachments',
            label: trans('test_records.overview.cell.attachments'),
            _style: 'width: 15%',
        },
        {
            key: 'user',
            label: trans('test_records.overview.cell.user'),
            _style: 'width: 200px; min-width: 200px; max-width: 200px',
        },
        {
            key: 'qr_code_scanned',
            label: trans('test_records.overview.cell.qr_code_scanned'),
            _style: 'width: 70px; min-width: 70px; max-width: 70px',
        },
    ].filter((field) => field),
);

const noItemsView = ref({
    noItems: trans('test_records.overview.results.empty'),
});

const openReports = ref([]);
const tests = computed(() => $page.props.tests);
const locations = computed(() => $page.props.locations);

const transformSortToQuery = (sort) => {
    if (!sort) {
        return undefined;
    }

    let sortDirection = sort.asc ? 'asc' : 'desc';
    return `${sort.column}.${sortDirection}`;
};

const updateSorting = (sort) => {
    activeQueryParams.value.sort = sort;
    loadTestRecords();
};

const showFullDescription = (item) => openReports.value.includes(item.id);
const toggleDetails = function (item) {
    if (openReports.value.includes(item.id)) {
        openReports.value = openReports.value.filter((id) => id !== item.id);
    } else {
        openReports.value.push(item.id);
    }
};

const updateFilter = function (filters) {
    activeQueryParams.value = { ...activeQueryParams.value, ...filters };
    loadTestRecords();
};

const getQueryParams = () => {
    let data = {
        ...activeQueryParams.value,
        start: activeQueryParams.value.date.start || null,
        end: activeQueryParams.value.date.end || null,
        sort: transformSortToQuery(activeQueryParams.value.sort),
    };

    delete data.date;
    return removeNullValues(data);
};

const loadTestRecords = () => {
    isProcessing.value = true;

    router.get(route('tests.index'), getQueryParams(), {
        only: ['tests'],
        preserveState: true,
        preserveScroll: true,
        onFinish: () => {
            isProcessing.value = false;
        },
    });
};
</script>
