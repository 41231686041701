<template>
    <div :class="classes" class="d-flex flex-wrap filterbar" style="gap: 5px">
        <div
            :class="{ 'd-sm-none': !$slots['visible-filter'] }"
            class="d-flex d-sm-block d-md-flex justify-content-between align-items-center"
            style="gap: 20px"
        >
            <slot :is-open="isOpen" name="visible-filter" />
            <button
                v-if="$slots.default"
                class="btn-text d-flex align-items-center d-sm-none"
                style="gap: 5px; min-height: 44px"
                @click.stop="toggleFilterbar"
            >
                <spinner v-if="isProcessing" />
                <icon
                    v-else
                    class="flex-shrink-0"
                    icon="cil-list-filter"
                    style="position: relative; top: -1px"
                />
                <span>{{ __('general.table.filter.label') }}</span>
            </button>
            <button
                v-else
                :class="{ 'opacity-100': isProcessing }"
                :disabled="!active"
                class="btn btn-text text-center mt-1 px-3 d-sm-none position-relative"
                @click="$emit('clear')"
            >
                <span
                    v-if="isProcessing"
                    class="d-flex position-absolute d-flex justify-content-center align-items-center"
                    style="top: 0; right: 0; bottom: 0; left: 0"
                >
                    <spinner :color="spinnerColor" />
                </span>
                <span
                    :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
                    >{{ __('general.action.clear') }}</span
                >
            </button>
        </div>
        <collapse v-if="$slots.default" :show="isOpen" class="d-sm-flex">
            <div
                :class="classes"
                class="d-flex flex-wrap"
                style="gap: 3px; min-height: 36px"
            >
                <slot />
                <div class="d-flex justify-content-between align-items-center">
                    <slot name="filter-end">
                        <button
                            :class="{ 'opacity-100': isProcessing }"
                            :disabled="!active"
                            class="btn btn-text text-center mt-1 px-3"
                            @click="$emit('clear')"
                        >
                            <spinner v-if="isProcessing && !isSmallScreen" />
                            <span v-else>{{ __('general.action.clear') }}</span>
                        </button>
                    </slot>
                </div>
            </div>
        </collapse>
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Collapse from '@/Components/Collapse.vue';
import Spinner from '@/Components/Spinner.vue';

export default {
    name: 'CollapsableFilterBar',
    components: { Spinner, Collapse, JetButton },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            width: document.body.clientWidth,
        };
    },
    computed: {
        classes() {
            return this.isSmallScreen
                ? 'flex-column'
                : ['flex-row', 'align-items-center'];
        },
    },
    created() {
        window.addEventListener('resize', this.updateSize);
        this.updateSize();
    },
    destroyed() {
        window.removeEventListener('resize', this.updateSize);
    },
    methods: {
        updateSize() {
            this.width = document.body.clientWidth;

            if (!this.isSmallScreen) {
                this.isOpen = true;
            }
        },
        toggleFilterbar() {
            if (this.isProcessing) {
                return;
            }

            this.isOpen = !this.isOpen;
        },
    },
};
</script>
