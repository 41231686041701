<template>
    <collapsable-filter-bar
        :active="activeFilters.isDirty"
        :is-processing="isProcessing"
        @clear="clearFilters"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                v-model="activeFilters.search"
                :is-processing="isProcessing"
                :placeholder="__('assets.overview.filter.placeholder')"
                :show-spinner="!isOpen"
            />
        </template>

        <location-filter v-model="activeFilters.locations" />
        <date-range-filter
            v-model="activeFilters.date"
            :label="__('activities.overview.cell.date')"
        />
        <multi-select-dropdown
            v-if="types.length > 1"
            v-model="activeFilters.types"
            :label="__('assets.overview.filter.type')"
            :options="types"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="gap: 1rem"
                >
                    <span>{{ option.label }}</span
                    ><small class="text-muted">{{ option.value }}</small>
                </div>
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-model="activeFilters.users"
            :label="__('activities.overview.cell.user')"
            :options="users"
            :show-searchbar="true"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option, show }">
                <truncated-label :show="show" :text="option.label" />
            </template>
        </multi-select-dropdown>
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import TruncatedLabel from '@/Pages/WorkOrders/TruncatedLabel.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import DateRangeFilter from '@/Components/DateRangeFilter.vue';

export default {
    components: {
        SearchFilter,
        TruncatedLabel,
        DateRangeFilter,
        LocationFilter,
        CollapsableFilterBar,
        MultiSelectDropdown,
    },
    props: {
        isProcessing: Boolean,
    },
    emits: ['change'],
    data() {
        return {
            activeFilters: this.$inertia.form({
                search: null,
                locations: [],
                date: {
                    start: null,
                    end: null,
                },
                types: [],
                users: [],
            }),
        };
    },
    computed: {
        types() {
            return this.$page.props.types?.data || [];
        },
        users() {
            return (
                this.$page.props.users?.data.map(({ name, id }) => ({
                    label: name,
                    value: id,
                })) || []
            );
        },
    },
    created() {
        this.readValuesFromUrl();
        window.addEventListener('keyup', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keyup', this.keyboardListener);
    },

    mounted() {
        this.readValuesFromUrl();

        this.$watch(
            (vm) => [
                vm.activeFilters.search,
                vm.activeFilters.locations,
                vm.activeFilters.date.start,
                vm.activeFilters.date.end,
                vm.activeFilters.types,
                vm.activeFilters.users,
            ],
            () => {
                this.$emit('change', this.activeFilters.data());
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },
    methods: {
        resetFilters() {
            this.activeFilters.reset();
        },
        readValuesFromUrl() {
            const { locations, start, end, types, users, search } =
                this.route().params;

            this.activeFilters.search = search || null;
            this.activeFilters.locations = locations || [];
            this.activeFilters.date.start = !!start ? new Date(start) : null;
            this.activeFilters.date.end = !!end ? new Date(end) : null;
            this.activeFilters.types = types || [];
            this.activeFilters.users = users || [];
        },
        clearFilters() {
            this.resetFilters();
        },
    },
};
</script>
