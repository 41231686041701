<template>
    <div class="d-flex" style="gap: 10px">
        <Link
            :href="cancelRoute"
            class="btn btn-secondary"
            v-text="__('general.action.cancel')"
        />
        <jet-button
            :disabled="disabled"
            :is-processing="isProcessing"
            color="primary"
            v-text="buttonLabel"
        />
    </div>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
        cancelRoute: String,
        isProcessing: {
            type: Boolean,
            default: false,
        },
        primaryActionLabel: String,
    },
    components: {
        JetButton,
    },
    computed: {
        buttonLabel() {
            if (this.primaryActionLabel) {
                return this.primaryActionLabel;
            }

            return this.isUpdateForm
                ? this.__('general.action.save')
                : this.__('general.action.create');
        },
    },
};
</script>
