<template>
    <row>
        <column>
            <jet-select
                v-model="selectedPhysicalLocationId"
                :enable-search="true"
                :horizontal="false"
                :invalidFeedback="
                    props.form.errors.location_id && !selectedPhysicalLocationId
                        ? __('assets.details.field.physical_location.error')
                        : undefined
                "
                :label="__('assets.details.field.physical_location.label')"
                :options="locations || []"
                :placeholder="
                    __('assets.details.field.physical_location.placeholder')
                "
                :required="true"
                @update:model-value="handlePhysicalLocationSelection($event)"
            >
                <template v-slot:item-label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span class="mr-3">{{ option.label }}</span
                        ><small class="text-muted">{{
                            option.iata_code
                        }}</small>
                    </div>
                </template>
            </jet-select>
        </column>
    </row>
    <row>
        <column>
            <jet-select
                v-model="props.form.location_id"
                :disabled="
                    !selectedPhysicalLocationId || isLoadingSpecificLocations
                "
                :enable-search="true"
                :horizontal="false"
                :invalidFeedback="props.form.errors.location_id"
                :label="__('assets.details.field.specific_location.label')"
                :options="specificLocations || []"
                :placeholder="
                    __('assets.details.field.specific_location.placeholder')
                "
                :required="true"
            >
                <template #empty
                    >{{ __('locations.field.specific_location.empty') }}
                </template>
                <template #description>
                    <div v-if="!selectedPhysicalLocationId">
                        <small
                            class="form-text text-muted w-100"
                            v-text="
                                __(
                                    'locations.field.specific_location.description',
                                )
                            "
                        />
                    </div>
                </template>
                <template #actions>
                    <button-add-new
                        v-if="
                            hasAnyPermission(
                                'physical_location.child.create',
                            ) && selectedPhysicalLocationId
                        "
                        @click.native="
                            createNewSpecificLocation(
                                selectedPhysicalLocationId,
                            )
                        "
                    />
                </template>
            </jet-select>
        </column>
    </row>
    <create-specific-location-modal
        @success="handleCreateSpecificLocationSuccess"
    />
</template>
<script setup>
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import JetSelect from '@/Jetstream/Select.vue';
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { $http } from '@/bootstrap.js';
import { eventBus, events } from '@/eventBus.js';
import CreateSpecificLocationModal from '@/Pages/Assets/Modals/CreateSpecificLocationModal.vue';

const props = defineProps({
    modelValue: Object,
    form: Object,
    asset: Object,
});

const $page = usePage();
const selectedPhysicalLocationId = ref(
    props.asset?.location.physical_location_id,
);

const isLoadingSpecificLocations = ref(false);

const transformSpecificLocations = function (locations) {
    return locations?.map((location) => ({
        value: location.id,
        label: location.name,
    }));
};

const locations = computed(() => {
    return (
        $page.props.physical_locations?.data.map((loc) => ({
            label: loc.name,
            value: loc.id,
            iata_code: loc.iata_code,
            searchableContent: `${loc.iata_code}|${loc.icao_code}|${loc.name}`,
        })) || []
    );
});

const specificLocations = ref(
    transformSpecificLocations($page.props.specific_locations?.data),
);

const handlePhysicalLocationSelection = function (physicalLocationId) {
    loadSpecificLocations({ locationId: physicalLocationId });
};

const loadSpecificLocations = function ({ locationId, selectedLocationId }) {
    specificLocations.value = [];
    if (!locationId) {
        return;
    }

    isLoadingSpecificLocations.value = true;
    props.form.processing = true;

    $http
        .get(route('airports.show', locationId))
        .then((resp) => {
            specificLocations.value = transformSpecificLocations(
                resp.data.specific_locations,
            );

            if (resp.data.specific_locations.length === 1) {
                props.form.location_id = resp.data.specific_locations[0].id;
            } else if (selectedLocationId) {
                props.form.location_id = selectedLocationId;
            }
        })
        .finally(() => {
            props.form.processing = false;
            isLoadingSpecificLocations.value = false;
        });
};

const handleCreateSpecificLocationSuccess = function (data) {
    loadSpecificLocations({
        locationId: selectedPhysicalLocationId.value,
        selectedLocationId: data.id,
    });
};

const createNewSpecificLocation = function (physicalLocationId) {
    eventBus.$emit(events.openCreateSpecificLocationModal, {
        physicalLocationId,
    });
};
</script>
